
// const socket = io('https://kymspace.co.uk', { transports: ['websocket', 'polling'], path: '/socket.io' });
import React, { useRef, useEffect, useState } from 'react';
import io from 'socket.io-client';

interface OfferData {
  offer: RTCSessionDescriptionInit;
  to: string;
}

interface AnswerData {
  answer: RTCSessionDescriptionInit;
  to: string;
}

interface IceCandidateData {
  candidate: RTCIceCandidateInit;
  to: string;
}

interface WatcherData {
  broadcasterId: string;
}

function Communication() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const pcRef = useRef<{ [key: string]: RTCPeerConnection }>({});
  const socketRef = useRef<ReturnType<typeof io> | null>(null);
  const isStreamingRef = useRef<boolean>(false);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const streamRef = useRef<MediaStream | null>(null);
  const [showPlayButton, setShowPlayButton] = useState<boolean>(false);
  const isScreenShareSupported = !!navigator.mediaDevices.getDisplayMedia;

  useEffect(() => {
    // Initialize Socket.IO
    // const socket = io('http://localhost:5000'); // Adjust the URL as needed
    const socket = io('https://kymspace.co.uk', { transports: ['websocket', 'polling'], path: '/socket.io' });

    socketRef.current = socket;

    socket.on('connect', () => {
      console.log('Connected to signaling server:', socket.id);
      socket.emit('join-room'); // Notify the server that we've joined
    });

    // Handle when a broadcaster starts streaming
    socket.on('broadcaster', (broadcasterId: string) => {
      if (isStreamingRef.current) {
        // If we are the broadcaster, no need to do anything
        return;
      }
      console.log('Received broadcaster info:', broadcasterId);
      socket.emit('watcher', { broadcasterId });
    });

    // Handle when a new viewer joins (only relevant if we are the broadcaster)
    socket.on('watcher', (viewerId: string) => {
      if (!isStreamingRef.current) {
        // If we are not the broadcaster, ignore
        return;
      }
      console.log('New viewer joined:', viewerId);
      createPeerConnection(viewerId);
      const peerConnection = pcRef.current[viewerId];

      // Add tracks to the peer connection
      streamRef.current?.getTracks().forEach((track) => {
        const sender = peerConnection.addTrack(track, streamRef.current!);

        // Adjust sender parameters for 720p 40fps // -----------------4K 120fps
        if (track.kind === 'video') {
          const parameters = sender.getParameters();
          if (!parameters.encodings || parameters.encodings.length === 0) {
            parameters.encodings = [{}];
          }
          parameters.encodings[0].maxBitrate = 25_000_000; // 25 Mbps
          parameters.encodings[0].maxFramerate = 120;
          sender.setParameters(parameters);
        }
      });

      peerConnection
        .createOffer()
        .then((offer) => peerConnection.setLocalDescription(offer))
        .then(() => {
          socket.emit('offer', {
            offer: peerConnection.localDescription!,
            to: viewerId,
          });
        });
    });

    socket.on('offer', (data: { offer: RTCSessionDescriptionInit; from: string }) => {
      const peerConnection = createPeerConnection(data.from);

      peerConnection
        .setRemoteDescription(new RTCSessionDescription(data.offer))
        .then(() => peerConnection.createAnswer())
        .then((answer) => peerConnection.setLocalDescription(answer))
        .then(() => {
          socket.emit('answer', {
            answer: peerConnection.localDescription!,
            to: data.from,
          });
        });
    });

    socket.on('answer', (data: { answer: RTCSessionDescriptionInit; from: string }) => {
      const peerConnection = pcRef.current[data.from];
      peerConnection.setRemoteDescription(new RTCSessionDescription(data.answer));
    });

    socket.on('ice-candidate', (data: { candidate: RTCIceCandidateInit; from: string }) => {
      const peerConnection = pcRef.current[data.from];
      peerConnection.addIceCandidate(new RTCIceCandidate(data.candidate));
    });

    socket.on('disconnectPeer', (peerId: string) => {
      console.log('Peer disconnected:', peerId);
      const peerConnection = pcRef.current[peerId];
      if (peerConnection) {
        peerConnection.close();
        delete pcRef.current[peerId];
      }
    });

    // Clean up on unmount
    return () => {
      socket.disconnect();
      Object.values(pcRef.current).forEach((pc) => pc.close());
    };
  }, []);

  const createPeerConnection = (peerId: string) => {
    let peerConnection = pcRef.current[peerId];
    if (peerConnection) return peerConnection;

    const configuration: RTCConfiguration = {
      iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
    };

    peerConnection = new RTCPeerConnection(configuration);
    pcRef.current[peerId] = peerConnection;

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        socketRef.current?.emit('ice-candidate', {
          candidate: event.candidate,
          to: peerId,
        });
      }
    };

    peerConnection.ontrack = (event) => {
      if (videoRef.current && !isStreamingRef.current) {
        console.log('Received remote stream');
        videoRef.current.srcObject = event.streams[0];

        // Show play button overlay
        setShowPlayButton(true);
      }
    };

    return peerConnection;
  };

  const startStream = async () => {
    if (!navigator.mediaDevices.getDisplayMedia) {
      alert('Screen sharing is not supported on your device.');
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          // width: { ideal: 3840 },
          // height: { ideal: 2160 },
          // frameRate: { ideal: 120, max: 120 },
          width: { ideal: 1920 }, // 1920 pixels for 1080p width
          height: { ideal: 1080 },  // 1080 pixels for 1080p height
          frameRate: { ideal: 120, max: 120 }, // Set frame rate to 120fps for high-motion content
  
            // width: { ideal: 1280 }, // 1280 pixels for 720p width
            // height: { ideal: 720 },  // 720 pixels for 720p height
            // frameRate: { ideal: 40, max: 40 }, // 30 fps is typical for 720p, but adjust as needed
    
        },
        audio: false,
      });
      console.log('Starting screen share with stream:', stream);

      isStreamingRef.current = true;
      setIsStreaming(true);
      streamRef.current = stream;

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.muted = true; // Mute to prevent echo
        videoRef.current
          .play()
          .then(() => {
            console.log('Local video is playing');
          })
          .catch((error) => {
            console.error('Error attempting to play local video:', error);
          });
      }

      socketRef.current?.emit('broadcaster');

      // Handle stop sharing
      stream.getVideoTracks()[0].addEventListener('ended', () => {
        stopStream();
      });
    } catch (err) {
      console.error('Error starting screen share:', err);
    }
  };

  const startCameraStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      console.log('Starting camera stream:', stream);

      isStreamingRef.current = true;
      setIsStreaming(true);
      streamRef.current = stream;

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.muted = true; // Mute to prevent echo
        await videoRef.current.play();
      }

      socketRef.current?.emit('broadcaster');

      // Handle stop sharing
      stream.getVideoTracks()[0].addEventListener('ended', () => {
        stopStream();
      });
    } catch (err) {
      console.error('Error starting camera stream:', err);
    }
  };

  const stopStream = () => {
    isStreamingRef.current = false;
    setIsStreaming(false);
    streamRef.current?.getTracks().forEach((track) => track.stop());
    streamRef.current = null;

    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }

    socketRef.current?.emit('stop');

    // Close all peer connections
    Object.values(pcRef.current).forEach((pc) => pc.close());
    pcRef.current = {};
  };

  const handlePlayButtonClick = () => {
    if (videoRef.current) {
      videoRef.current
        .play()
        .then(() => {
          console.log('Video is playing');
          setShowPlayButton(false); // Hide the play button
        })
        .catch((error) => {
          console.error('Error attempting to play video:', error);
        });
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        overflow: 'visible',
        backgroundColor: '#121212',
        color: '#fff',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <h1 style={{ marginTop: '20px' }}>Screen Sharing Test</h1>
      {isScreenShareSupported ? (
        !isStreaming && (
          <button
            onClick={startStream}
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              cursor: 'pointer',
              borderRadius: '5px',
              backgroundColor: '#1DB954',
              color: 'white',
              border: 'none',
              marginBottom: '20px',
            }}
          >
            Start Screen Share
          </button>
        )
      ) : (
        !isStreaming && (
          <>
            <p style={{ marginBottom: '10px' }}>
              Screen sharing is not supported on your device. You can start a camera stream instead.
            </p>
            <button
              onClick={startCameraStream}
              style={{
                padding: '10px 20px',
                fontSize: '16px',
                cursor: 'pointer',
                borderRadius: '5px',
                backgroundColor: '#1DB954',
                color: 'white',
                border: 'none',
                marginBottom: '20px',
              }}
            >
              Start Camera Stream
            </button>
          </>
        )
      )}
      {isStreaming && (
        <button
          onClick={stopStream}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            borderRadius: '5px',
            backgroundColor: '#FF0000',
            color: 'white',
            border: 'none',
            marginBottom: '20px',
          }}
        >
          Stop Stream
        </button>
      )}
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          overflow: 'auto',
          width: '100%',
        }}
      >
        <video
          ref={videoRef}
          playsInline
          controls={false}
          style={{
            width: '70%',
            maxWidth: '1200px',
            margin: '0 10px',
            borderRadius: '10px',
            backgroundColor: '#000',
          }}
        ></video>
        {showPlayButton && (
          <div
            style={{
              position: 'absolute',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
              width: '70%',
              maxWidth: '1200px',
              height: '70%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '10px',
            }}
            onClick={handlePlayButtonClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="64"
              viewBox="0 0 24 24"
              width="64"
              fill="#fff"
            >
              <path d="M8 5v14l11-7z" />
            </svg>
            <p style={{ fontSize: '18px' }}>Click to Start the Stream</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Communication;
